#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  @apply bg-legacy-primary-900 dark:bg-legacy-primary-300;
}
